.typing-indicator {
    display: flex;
    align-items: center;
    margin: 5px 0;  /* Adjust margin as needed */
  }
  
  .dot {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: white;
    animation: blink 1.4s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 20%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
  }
  
  /* For WebKit browsers (Chrome, Safari) */
  #chatbox::-webkit-scrollbar {
    width: 12px;  /* Width of the scrollbar */
  }
  
  #chatbox::-webkit-scrollbar-track {
    background: #222; /* Dark background for the scrollbar track */
  }
  
  #chatbox::-webkit-scrollbar-thumb {
    background: #555; /* Color for the scrollbar thumbs */
    border-radius: 6px; /* Rounded edges for the scrollbar thumb */
  }
  
  #chatbox::-webkit-scrollbar-thumb:hover {
    background: #777; /* Lighter color on hover */
  }
  
  /* For Firefox */
  #chatbox {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #555 #222; /* Thumb color and track color */
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #121212; /* Dark background for the body */
    color: white;               /* Default text color for dark mode */
  }
  
  #chatbox {
    border: 1px solid #444; /* Darker border */
    border-radius: 5px;
    padding: 10px;
    flex: 1;
    overflow-y: scroll;
    background-color: #222; /* Darker background for chatbox */
  }
  
  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444; /* Dark border */
    background-color: #333; /* Background input color */
    color: white;           /* Input text color */
  }
  
  button {
    padding: 10px;
    border-radius: 5px;
    border: none;          /* Remove border */
    background-color: #007BFF; /* Blue button background */
    color: white;         /* White text on buttons */
    cursor: pointer;      /* Pointer cursor */
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  button:focus {
    outline: none; /* Remove focus outline */
  }
  
  .message {
    white-space: pre-wrap; /* Preserve whitespace */
  }
  
  .message-user {
    text-align: right;      /* Align user messages to the right */
  }
  
  .message-ava {
    text-align: left;       /* Align Ava's messages to the left */
  }
  
  .loading, .streaming, .error {
    color: orange;          /* Lighter color for loading */
  }
  
  /* Specific styles for individual message roles */
  .user-role {
    color: #5897da;        /* User role color */
  }
  
  .ava-role {
    color: #d6a0d4;        /* Ava role color */
  }